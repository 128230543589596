<template>
  <v-container fill-height pa-0 fluid class="pre-game-settings">
    <v-layout column>
      <v-flex d-flex class="header-container">
        <v-layout column>
          <v-flex shrink>
            <Header headline="EXPO">
              <v-layout row class="inner-header-row">
                <v-flex
                  xs6
                  d-flex
                  align-center
                  class="page-headline"
                  v-slot:potato
                >
                  <v-layout>
                    <v-flex
                      shrink
                      class="remote-reception-text"
                      d-flex
                      align-center
                    >
                      {{ expoText }}
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 d-flex pr-2>
                  <v-layout row justify-end>
                    <v-flex shrink d-flex align-center v-if="isHost">
                      <span class="time-link" @click="onClose()">
                        BACK TO RECEPTION
                      </span>
                    </v-flex>
                    <template v-if="!!user">
                      <v-flex
                        shrink
                        pr-1
                        d-flex
                        align-center
                        class="logged-in-as-text"
                      >
                        Logged in as:
                      </v-flex>
                      <v-flex
                        shrink
                        d-flex
                        align-center
                        class="logged-in-user-name"
                      >
                        <UserName
                          :firstname="user.firstname"
                          :lastname="user.lastname"
                        />
                      </v-flex>
                    </template>
                    <v-flex shrink d-flex align-center>
                      <div
                        class="logout-btn-wrap"
                        @click="$router.push('/logout')"
                      >
                        <div class="logout-btn">LOG OUT</div>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </Header>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex d-flex>
        <ClientSettings
          :client="client"
          :clientID="clientID"
          @closeClientSettings="onClose()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import ClientSettings from "@/components/Game/ClientSettings"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import Header from "@/components/GroupTeams/Common/Header"

export default {
  name: "EventSettings",
  components: {
    ClientSettings,
    UserName,
    Header
  },
  computed: {
    ...mapGetters("auth", ["user", "clientID", "client", "isHost", "isAudit"])
  },
  methods: {
    onClose() {
      this.$router.push(`/reception/${this.clientID}`)
    }
  }
}
</script>

<style lang="scss">
.pre-game-settings {
  .rtb-header {
    position: relative;
    z-index: 10;
  }
  .time-link {
    cursor: pointer;
    position: relative;
    z-index: 99;
    margin-right: 5px;
    font-size: 11px;
    text-decoration: underline;
  }
  .time-link {
    color: $logo_color;
  }
  .logout-btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .logout-btn {
      white-space: nowrap;
      margin-top: 1px;
      line-height: 12px;
      position: relative;
      color: #fff;
      font-size: 10px;
      border-radius: 0.75rem;
      padding: 1px 12px 0;
      border: 1px solid $primary_accent_color;
      z-index: 99;
      user-select: none;
      cursor: pointer;
      &:hover {
        border: 1px solid $secondary_accent_color;
      }
    }
  }
  .logged-in-user-name {
    color: $color-secondary-light;
    text-transform: uppercase;
    font-weight: bold;
    padding-right: 15px;
  }
  .logged-in-as-text {
    color: $color-secondary-light;
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 10px;
  }
  .header-container {
    flex: 0 0 auto;
  }
}
</style>
