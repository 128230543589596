var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pre-game-settings",
      attrs: { "fill-height": "", "pa-0": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "header-container", attrs: { "d-flex": "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "Header",
                        { attrs: { headline: "EXPO" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "inner-header-row",
                              attrs: { row: "" }
                            },
                            [
                              _c("v-flex", {
                                staticClass: "page-headline",
                                attrs: {
                                  xs6: "",
                                  "d-flex": "",
                                  "align-center": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "potato",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "remote-reception-text",
                                                attrs: {
                                                  shrink: "",
                                                  "d-flex": "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.expoText) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _c(
                                "v-flex",
                                {
                                  attrs: { xs6: "", "d-flex": "", "pr-2": "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", "justify-end": "" } },
                                    [
                                      _vm.isHost
                                        ? _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                shrink: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "time-link",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onClose()
                                                    }
                                                  }
                                                },
                                                [_vm._v(" BACK TO RECEPTION ")]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !!_vm.user
                                        ? [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "logged-in-as-text",
                                                attrs: {
                                                  shrink: "",
                                                  "pr-1": "",
                                                  "d-flex": "",
                                                  "align-center": ""
                                                }
                                              },
                                              [_vm._v(" Logged in as: ")]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "logged-in-user-name",
                                                attrs: {
                                                  shrink: "",
                                                  "d-flex": "",
                                                  "align-center": ""
                                                }
                                              },
                                              [
                                                _c("UserName", {
                                                  attrs: {
                                                    firstname:
                                                      _vm.user.firstname,
                                                    lastname: _vm.user.lastname
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            shrink: "",
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "logout-btn-wrap",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$router.push(
                                                    "/logout"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "logout-btn" },
                                                [_vm._v("LOG OUT")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "d-flex": "" } },
            [
              _c("ClientSettings", {
                attrs: { client: _vm.client, clientID: _vm.clientID },
                on: {
                  closeClientSettings: function($event) {
                    return _vm.onClose()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }